import xlsx from "xlsx";
import axios from "axios";

export function buildSheet(name, struct, startYear, endYear) {
  var rows = [];
  for (let row in struct) {
    let cols = {};
    cols["项目名"] = struct[row].text;
    cols["行次"] = struct[row].row;
    for (let i = startYear; i <= endYear; i++) {
      if (
        name === "现金流量表" &&
        (struct[row].row === 1 ||
          struct[row].row === 12 ||
          struct[row].row === 25)
      ) {
        cols[i + "年"] = "";
      } else {
        cols[i + "年"] = 0;
      }
    }
    rows.push(cols);
  }
  const sheet = xlsx.utils.aoa_to_sheet([[name]]);
  sheet["!merges"] = [
    {
      s: {
        c: 0,
        r: 0,
      },
      e: {
        c: endYear - startYear + 2,
        r: 0,
      },
    },
  ];
  sheet["A1"].s = {
    alignment: {
      horizontal: "center",
    },
    fill: {
      fgColor: {
        rgb: "FF2D8E5B",
      },
    },
    font: {
      color: {
        rgb: "FFFFFFFF",
      },
    },
  };
  xlsx.utils.sheet_add_json(sheet, rows, { origin: "A2" });
  for (let i = 65; i < 65 + (endYear - startYear) + 3; i++) {
    var char = String.fromCharCode(i);
    sheet[char + "2"].s = {
      alignment: {
        horizontal: "center",
      },
      fill: {
        fgColor: {
          rgb: "FF2D8E5B",
        },
      },
      font: {
        color: {
          rgb: "FFFFFFFF",
        },
      },
    };
    switch (name) {
      case "资产负债表":
        sheet[char + "18"].s = {
          fill: {
            fgColor: {
              rgb: "FFFFFF03",
            },
          },
        };
        sheet[char + "45"].s = {
          fill: {
            fgColor: {
              rgb: "FFFFFF03",
            },
          },
        };
        sheet[char + "46"].s = {
          fill: {
            fgColor: {
              rgb: "FFFFFF03",
            },
          },
        };
        sheet[char + "60"].s = {
          fill: {
            fgColor: {
              rgb: "FFFFFF03",
            },
          },
        };
        sheet[char + "69"].s = {
          fill: {
            fgColor: {
              rgb: "FFFFFF03",
            },
          },
        };
        sheet[char + "70"].s = {
          fill: {
            fgColor: {
              rgb: "FFFFFF03",
            },
          },
        };
        sheet[char + "94"].s = {
          fill: {
            fgColor: {
              rgb: "FFFFFF03",
            },
          },
        };
        sheet[char + "95"].s = {
          fill: {
            fgColor: {
              rgb: "FFFFFF03",
            },
          },
        };
        break;
      case "利润表":
        sheet[char + "14"].s = {
          fill: {
            fgColor: {
              rgb: "FFFFFF03",
            },
          },
        };
        sheet[char + "18"].s = {
          fill: {
            fgColor: {
              rgb: "FFFFFF03",
            },
          },
        };
        sheet[char + "20"].s = {
          fill: {
            fgColor: {
              rgb: "FFFFFF03",
            },
          },
        };
        break;
      case "现金流量表":
        sheet[char + "7"].s = {
          fill: {
            fgColor: {
              rgb: "FFFFFF03",
            },
          },
        };
        sheet[char + "12"].s = {
          fill: {
            fgColor: {
              rgb: "FFFFFF03",
            },
          },
        };
        sheet[char + "13"].s = {
          fill: {
            fgColor: {
              rgb: "FFFFFF03",
            },
          },
        };
        sheet[char + "20"].s = {
          fill: {
            fgColor: {
              rgb: "FFFFFF03",
            },
          },
        };
        sheet[char + "25"].s = {
          fill: {
            fgColor: {
              rgb: "FFFFFF03",
            },
          },
        };
        sheet[char + "26"].s = {
          fill: {
            fgColor: {
              rgb: "FFFFFF03",
            },
          },
        };
        sheet[char + "31"].s = {
          fill: {
            fgColor: {
              rgb: "FFFFFF03",
            },
          },
        };
        sheet[char + "35"].s = {
          fill: {
            fgColor: {
              rgb: "FFFFFF03",
            },
          },
        };
        sheet[char + "36"].s = {
          fill: {
            fgColor: {
              rgb: "FFFFFF03",
            },
          },
        };
        sheet[char + "40"].s = {
          fill: {
            fgColor: {
              rgb: "FFFFFF03",
            },
          },
        };
        break;
    }
  }
  for (let col = 67; col <= 67 + (endYear - startYear); col++) {
    var char = String.fromCharCode(col);
    switch (name) {
      case "资产负债表":
        sheet[char + "18"].f = `SUM(${char}3:${char}12,${char}15:${char}17)`;
        sheet[
          char + "45"
        ].f = `SUM(${char}19:${char}24,${char}28:${char}34,${char}36:${char}39,${char}42:${char}43)`;
        sheet[char + "46"].f = `SUM(${char}18,${char}45)`;
        sheet[char + "60"].f = `SUM(${char}47:${char}59)`;
        sheet[char + "69"].f = `SUM(${char}61:${char}68)`;
        sheet[char + "70"].f = `SUM(${char}60,${char}69)`;
        sheet[
          char + "94"
        ].f = `SUM(${char}71,${char}79,${char}82,${char}84,${char}88,${char}90,${char}93)-${char}83`;
        sheet[char + "95"].f = `SUM(${char}70,${char}94)`;
        break;
      case "利润表":
        sheet[
          char + "14"
        ].f = `${char}3-SUM(${char}4:${char}8,${char}10)+${char}11+${char}12`;
        sheet[char + "18"].f = `${char}14+${char}15-${char}16`;
        sheet[char + "20"].f = `${char}18-${char}19`;
        break;
      case "现金流量表":
        sheet[char + "7"].f = `SUM(${char}4:${char}6)`;
        sheet[char + "12"].f = `SUM(${char}8:${char}11)`;
        sheet[char + "13"].f = `${char}7-${char}12`;
        sheet[char + "20"].f = `SUM(${char}15:${char}19)`;
        sheet[char + "25"].f = `SUM(${char}21:${char}24)`;
        sheet[char + "26"].f = `${char}20-${char}25`;
        sheet[char + "31"].f = `SUM(${char}28:${char}30)`;
        sheet[char + "35"].f = `SUM(${char}32:${char}34)`;
        sheet[char + "36"].f = `${char}31-${char}35`;
        sheet[char + "40"].f = `${char}38+${char}39`;
    }
  }
  if (name === "现金流量表") {
    sheet["!merges"].push({
      s: {
        c: 2,
        r: 2,
      },
      e: {
        c: endYear - startYear + 2,
        r: 2,
      },
    });
    sheet["!merges"].push({
      s: {
        c: 2,
        r: 13,
      },
      e: {
        c: endYear - startYear + 2,
        r: 13,
      },
    });
    sheet["!merges"].push({
      s: {
        c: 2,
        r: 26,
      },
      e: {
        c: endYear - startYear + 2,
        r: 26,
      },
    });
  }
  return sheet;
}

export function s2ab(s) {
  if (typeof ArrayBuffer !== "undefined") {
    var buf = new ArrayBuffer(s.length);
    var view = new Uint8Array(buf);
    for (var i = 0; i != s.length; ++i) view[i] = s.charCodeAt(i) & 0xff;
    return buf;
  } else {
    var buf = new Array(s.length);
    for (var i = 0; i != s.length; ++i) buf[i] = s.charCodeAt(i) & 0xff;
    return buf;
  }
}
const getEN = (encn, cn) => {
  for (const en in encn) {
    if (encn.hasOwnProperty(en)) {
      if ($.trim(encn[en]) == $.trim(cn)) {
        return en;
        break;
      }
    }
  }
};
export function saveAs(obj, fileName) {
  var tmpa = document.createElement("a");
  tmpa.download = fileName || "下载";
  tmpa.href = URL.createObjectURL(obj);
  tmpa.click();
  setTimeout(function() {
    URL.revokeObjectURL(obj);
  }, 100);
}

/**
 * 将allData格式转换为API所需格式
 * @param {Object} excel FillCompanyDataTable中allData格式
 */
export function formatExcelDataToApiJSON(
  excel,
  startYear,
  endYear,
  company_type
) {
  // 资产负债表
  return new Promise((resolve, reject) => {
    axios
      .get(`static/excel/${company_type}/cn-en.json`, {})
      .then((resp) => {
        return resp.data;
      })
      .then((cnens) => {
        console.info("getCNENs", cnens);

        var result = {};
        for (let year = startYear; year <= endYear; year++) {
          let yearData = formatSheet(excel, year, cnens, company_type);

          if (yearData.isError) {
            reject(yearData.msg);
          } else {
            result[year + ""] = yearData.result;
          }
        }

        for (const key in cnens.zcfzb) {
          if (cnens.zcfzb.hasOwnProperty(key)) {
            // console.log(key, result["2016"][0].items.item[key]);
          }
        }

        // console.log(JSON.stringify(result));
        resolve(result);
      });
  });
}

function formatSheet(excel, year, cnens, company_type) {
  if (company_type != 2) {
    // 一般企业
    var zcfzb = {
      code: "FB",
      name: "资产负债表",
      items: {
        item: {},
      },
    };
    var zcfzbSheet = excel.zcfzb;
    var len = zcfzbSheet.length;
    var zcfzbENCN = cnens.zcfzb;

    while (len--) {
      let row = zcfzbSheet[len];
      let rowNum = row["行次"];
      // console.info('行次' + rowNum + ' ' + year + '年', row[year + '年'])
      // // if (rowNum === 16 || rowNum === 58 || rowNum === 67 || rowNum === 68 || rowNum === 92 || rowNum === 93) {
      // //   if (row[year + '年'] === undefined) {
      // //     return {
      // //       isError: true,
      // //       msg: '流动资产合计、流动负债合计、非流动负债合计、负债合计所有者权益（或股东权益）合计、负债和所有者权益（或股东权益）总计数据不能为空',
      // //       result: []
      // //     }
      // //   }
      // // }
      var en = getEN(zcfzbENCN, row["项目名"]);
      // console.log(en, row["项目名"], row[year + "年"]);
      zcfzb.items.item[en] = row[year + "年"];
    }
    var lrb = {
      code: "FP",
      name: "利润表",
      items: {
        item: {},
      },
    };
    var lrbSheet = excel.lrb;
    len = lrbSheet.length;
    var lrbENCN = cnens.lrb;
    while (len--) {
      let row = lrbSheet[len];

      // if (rowNum === 1 || rowNum === 12 || rowNum === 17 || rowNum === 18) {
      //   if (row[year + '年'] === undefined) {
      //     return {
      //       isError: true,
      //       msg: '营业收入、营业成本、营业利润、所得税费用、净利润等数据不能为空',
      //       result: []
      //     }
      //   }
      // }
      var en = getEN(lrbENCN, row["项目名"]);
      // console.log(en, row["项目名"], row[year + "年"]);
      lrb.items.item[en] = row[year + "年"];
    }
    var xjllb = {
      code: "FC",
      name: "现金流量表",
      items: {
        item: {},
      },
    };
    var xjllbSheet = excel.xjllb;
    len = xjllbSheet.length;
    var xjllbENCN = cnens.xjllb;
    while (len--) {
      let row = xjllbSheet[len];
      let rowNum = row["行次"];
      // if (rowNum === 11 || rowNum === 24 || rowNum === 34 || rowNum === 36 || rowNum === 38) {
      //   if (row[year + '年'] === undefined) {
      //     return {
      //       isError: true,
      //       msg: '经营活动产生的现金流量净额、投资活动产生的现金流量净额、筹资活动产生的现金流量净额、现金及现金等价物净增加额、期末现金及现金等价物余额等数据不能为空',
      //       result: []
      //     }
      //   }
      // }
      var en = getEN(xjllbENCN, row["项目名"]);
      // console.log(en, row["项目名"], row[year + "年"]);
      xjllb.items.item[en] = row[year + "年"];
    }

    /*
          校验
        */
    // var zcfzbCheck = {
    //   "current_assets_total": "流动资产合计",
    //   "current_liability_total": "流动负债合计",
    //   "nc_liability_total": "非流动负债合计",
    //   "owner_equity_total": "所有者权益(或股东权益)合计",
    //   "liabilities_equity_total": "负债和所有者权益(或股东权益) 总计"
    // }
    // for (const en in zcfzbCheck) {
    //   if (zcfzbCheck.hasOwnProperty(en)) {
    //     if(zcfzb["items"]["item"][en] === undefined) {
    //       return {
    //         isError: true,
    //         msg: '流动资产合计、流动负债合计、非流动负债合计、负债合计所有者权益（或股东权益）合计、负债和所有者权益（或股东权益）总计数据不能为空',
    //         result: []
    //       }
    //     }

    //   }
    // }

    // var lrbCheck = {
    //   "operating_receipt": "一、营业收入",
    //   "cost_in_business":"减：营业成本",
    //   "operating_profit":"二、营业利润（亏损以“-”号填列）",
    //   "income_tax":"减：所得税费用",
    //   "net_margin":"四、净利润（净亏损以“-”号填列）",
    // }
    // for (const en in lrbCheck) {
    //   if (lrbCheck.hasOwnProperty(en)) {
    //     if(lrb["items"]["item"][en] === undefined) {
    //       return {
    //         isError: true,
    //         msg: '营业收入、营业成本、营业利润、所得税费用、净利润等数据不能为空',
    //         result: []
    //       }
    //     }

    //   }
    // }
    // var xjllbCeck = {
    //   "management_net_income":"经营活动产生的现金流量净额",
    //   "investment_net_income":"投资活动产生的现金流量净额",
    //   "financing_net_income":"筹资活动产生的现金流量净额",
    //   "cash_goods_increase":"五、现金及现金等价物净增加额",
    //   "last_cash_goods_balance":"六、期末现金及现金等价物余额"
    // }
    // for (const en in xjllbCeck) {
    //   if (xjllbCeck.hasOwnProperty(en)) {
    //     if(xjllb["items"]["item"][en] === undefined) {
    //       return {
    //         isError: true,
    //         msg: '经营活动产生的现金流量净额、投资活动产生的现金流量净额、筹资活动产生的现金流量净额、现金及现金等价物净增加额、期末现金及现金等价物余额等数据不能为空',
    //         result: []
    //       }
    //     }
    //   }
    // }
    return {
      isError: false,
      msg: "",
      result: [zcfzb, lrb, xjllb],
    };
  } else if (company_type == 2) {
    var FB = {
      code: "FB",
      name: "资产负债表",
      items: {
        item: {},
      },
    };
    var FBSheet = excel.FB;
    var len = FBSheet.length;

    while (len--) {
      let row = FBSheet[len];
      let rowNum = row["行次"];

      var en = getEN(cnens.FB, row["项目名"]);

      FB.items.item[en] = row[year + "年"];
    }
    var FP = {
      code: "FP",
      name: "利润表",
      items: {
        item: {},
      },
    };
    var FPSheet = excel.FP;
    len = FPSheet.length;

    while (len--) {
      let row = FPSheet[len];
      let rowNum = row["行次"];
      var en = getEN(cnens.FP, row["项目名"]);

      FP.items.item[en] = row[year + "年"];
    }
    var FC = {
      code: "FC",
      name: "现金流量表",
      items: {
        item: {},
      },
    };

    var FCSheet = excel.FC;
    len = FCSheet.length;
    while (len--) {
      let row = FCSheet[len];
      var en = getEN(cnens.FC, row["项目名"]);

      FC.items.item[en] = row[year + "年"];
    }
    var FA = {
      code: "FA",
      name: "现金流量表",
      items: {
        item: {},
      },
    };

    var FASheet = excel.FA;
    len = FASheet.length;
    while (len--) {
      let row = FASheet[len];
      let rowNum = row["行次"];

      var en = getEN(cnens.FA, row["项目名"]);

      FA.items.item[en] = row[year + "年"];
    }

    return {
      isError: false,
      msg: "",
      result: [FB, FP, FC, FA],
    };
  }
}

function watermark(canvas, text = "数行科技", ctx, page) {
  const fontSize = 100;
  const pageHeight = (canvas.width / 592.28) * 841.89;

  const textX = canvas.width / 2 - (text.length / 2) * fontSize;

  // 设置填充字号和字体，样式
  ctx.font = `${fontSize}px 宋体`;
  ctx.fillStyle = "rgba(0, 0, 0, 0.1)";
  let textY = pageHeight / 2 - fontSize / 2;

  for (let i = 0; i <= page; i++) {
    ctx.fillText(text, textX, textY + i * pageHeight);
  }
  // ctx.fillText(text, textX, text)
  // ctx.fillText(text, textX+pageHeight, textX+200)
  // ctx.fillText(text, (textX+pageHeight + 1200), textX+200+200+500)
  // ctx.fillText(text, (textX+pageHeight + 1200+1200), textX+200+200+500+ 200+500)
  // ctx.fillText(text, (textX+pageHeight + 1200+1200+1200), textX+200+200+500+ 200+500+ 200+500)
}
const setCanvasBg = (ctx, width, height) => {
  ctx.fillStyle = "#fff";
  ctx.fillRect(0, 0, width, height);
};
import product_info from "@/store/product_info.js";

const setCanvas = (canvas) => {
  const newCanvas = document.createElement("CANVAS");
  var pageHeight = (canvas.width / 592.28) * 841.89;
  const page = Math.ceil(canvas.height / pageHeight);
  newCanvas.width = canvas.width;

  newCanvas.height = canvas.height;
  const ctx = newCanvas.getContext("2d");
  setCanvasBg(ctx, newCanvas.width, newCanvas.height);

  // 绘制水印 只有适应两页

  ctx.drawImage(canvas, 0, 0, canvas.width, canvas.height);

  // const newCanvas1 =  document.createElement("CANVAS")
  // newCanvas1.height = newCanvas.height;
  // newCanvas1.width = newCanvas.width;
  // const ctx1 = newCanvas1.getContext('2d')
  watermark(newCanvas, product_info.name, ctx, page);
  // return $("html").html(newCanvas)
  return newCanvas;
};

/*
  name: publicDownPDF
  alert
*/
export const publicDownPDF = function(name, cb, createEleArr) {
  // this.hiddenShadow();
  const left = 1000000;
  this.$nextTick(() => {
    // 创建容器 和 头部区域
    var container = document.createElement("div");
    container.style.position = "absolute";
    container.style.width = "1110px";
    container.style.top = "0";
    container.style.left = "0%";
    container.style.transform = `translateX(${left}px)`;

    container.style.zIndex = 10;
    container.style.background = "#fff";
    // container.style.zIndex = -1;
    var companyHeader = document.querySelector("#companyHeadContainer");
    if (companyHeader) {
      var companyHeader = document
        .querySelector("#companyHeadContainer")
        .cloneNode(true);
      if (companyHeader.querySelector(".hot-wrap"))
        companyHeader.querySelector(".hot-wrap").style.visibility = "hidden";
      // companyHeader.querySelector(
      //   "#industry"
      // ).innerHTML = `<h3>审计年份：${this.selectedYear}</h3>`;

      container.appendChild(companyHeader);
      var title = document.createElement("h2");
      title.innerText = name;
      title.style.paddingLeft = "30px";
      container.appendChild(title);
    }

    // 需要生成的元素的id
    createEleArr.map((item, index) => {
      const ele = $(`#${item.id}`);
      const cloneEle = ele.clone(true);
      const canvasCloneEle = cloneEle.find("canvas");

      if (canvasCloneEle[0]) {
        const canvasEle = ele.find("canvas");
        [].map.call(canvasEle, (ele, index) => {
          $(canvasCloneEle[index]).replaceWith(
            `<img style="width: 100%" src="${ele.toDataURL()}">`
          );
        });
      }
      !index &&
        cloneEle.css({
          paddingLeft: "30px",
          paddingTop: "30px",
        });
      container.appendChild(cloneEle[0]);
    });

    // this.$refs.CustomRule.CustomRuleAllTable(container);
    document.body.append(container);
    // return false;

    import("html2canvas")
      .then((html2canvas) => {
        html2canvas
          .default(container)
          .then((canvas) => {
            var canvas = setCanvas(canvas);

            var pageData = canvas.toDataURL("image/jpeg", 1.0);
            document.body.removeChild(container);
            import("jspdf")
              .then((jsPDF) => {
                jsPDF = jsPDF.default;
                /**jspdf将html转为pdf一页显示不截断，整体思路：
                 * 1. 获取DOM
                 * 2. 将DOM转换为canvas
                 * 3. 获取canvas的宽度、高度（稍微大一点）
                 * 4. 将pdf的宽高设置为canvas的宽高
                 * 5. 将canvas转为图片
                 * 6. 实例化jspdf，将内容图片放在pdf中（因为内容宽高和pdf宽高一样，就只需要一页，也防止内容截断问题）
                 */

                // 得到canvas画布的单位是px 像素单位
                var contentWidth = canvas.width;
                var contentHeight = canvas.height;

                // console.log("contentWidth", contentWidth);
                // console.log("contentHeight", contentHeight);
                // 将canvas转为base64图片

                // 设置pdf的尺寸，pdf要使用pt单位 已知 1pt/1px = 0.75   pt = (px/scale)* 0.75
                // 2为上面的scale 缩放了2倍
                var pdfX = ((contentWidth + 10) / 2) * 0.75;
                var pdfY = ((contentHeight + 500) / 2) * 0.75; // 500为底部留白

                // 设置内容图片的尺寸，img是pt单位
                var imgX = pdfX;
                var imgY = (contentHeight / 2) * 0.75; //内容图片这里不需要留白的距离

                // 初始化jspdf 第一个参数方向：默认''时为纵向，第二个参数设置pdf内容图片使用的长度单位为pt，第三个参数为PDF的大小，单位是pt
                var PDF = new jsPDF("", "pt", [pdfX, pdfY]);

                // 将内容图片添加到pdf中，因为内容宽高和pdf宽高一样，就只需要一页，位置就是 0,0
                PDF.addImage(pageData, "jpeg", 0, 0, imgX, imgY);
                PDF.save(`${name || "down"}.pdf`);
                cb && cb();
              })
              .catch((err) => {
                console.error(err);
                this.$loadingHide();
                document.body.removeChild(container);
                cb && cb();
              });
          })
          .catch((err) => {
            this.$loadingHide();
            console.error(err);
            document.body.removeChild(container);
            cb && cb();
          });
      })
      .catch((err) => {
        this.$loadingHide();
        console.error(err);
        document.body.removeChild(container);
        cb && cb();
      });
  });
};

export const publicDownPDF1 = function(name, cb, createEleArr) {
  // this.hiddenShadow();
  this.$nextTick(() => {
    // 创建容器 和 头部区域
    var container = document.createElement("div");
    container.style.position = "absolute";
    container.style.width = "1110px";
    container.style.top = "0";
    container.style.left = "0";
    // container.style.transform = "translateY(100%)";

    container.style.zIndex = 10;
    container.style.background = "#fff";
    // container.style.zIndex = -1;
    var companyHeader = document.querySelector("#companyHeadContainer");
    if (companyHeader) {
      var companyHeader = document
        .querySelector("#companyHeadContainer")
        .cloneNode(true);
      if (companyHeader.querySelector(".hot-wrap"))
        companyHeader.querySelector(".hot-wrap").style.visibility = "hidden";
      // companyHeader.querySelector(
      //   "#industry"
      // ).innerHTML = `<h3>审计年份：${this.selectedYear}</h3>`;

      container.appendChild(companyHeader);
      var title = document.createElement("h2");
      title.innerText = name;
      title.style.paddingLeft = "30px";
      container.appendChild(title);
    }

    // 需要生成的元素的id
    createEleArr.map((item, index) => {
      const ele = $(`#${item.id}`);
      const cloneEle = ele.clone(true);
      const canvasCloneEle = cloneEle.find("canvas");

      if (canvasCloneEle[0]) {
        const canvasEle = ele.find("canvas");
        [].map.call(canvasEle, (ele, index) => {
          $(canvasCloneEle[index]).replaceWith(
            `<img style="width: 100%;height: 100%" src="${ele.toDataURL()}">`
          );
        });
      }
      !index &&
        cloneEle.css({
          paddingLeft: "30px",
          paddingTop: "30px",
        });
      container.appendChild(cloneEle[0]);
    });

    // this.$refs.CustomRule.CustomRuleAllTable(container);

    // $("body").html(container);
    // return false;
    var domtoimage = require("dom-to-image");
    $(container)
      .find(".box")
      .css({
        boxShadow: "0 0 8px #fff",
      });
    document.body.append(container);

    domtoimage.toSvg(container).then((base64) => {
      const img = new Image();

      img.onload = () => {
        var canvas = document.createElement("CANVAS");
        canvas.width = img.width;
        canvas.height = img.height;

        const ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0, canvas.width, canvas.height);

        var canvas = setCanvas(canvas);

        var pageData = canvas.toDataURL("image/jpeg", 1.0);

        import("jspdf")
          .then((jsPDF) => {
            jsPDF = jsPDF.default;
            /**jspdf将html转为pdf一页显示不截断，整体思路：
             * 1. 获取DOM
             * 2. 将DOM转换为canvas
             * 3. 获取canvas的宽度、高度（稍微大一点）
             * 4. 将pdf的宽高设置为canvas的宽高
             * 5. 将canvas转为图片
             * 6. 实例化jspdf，将内容图片放在pdf中（因为内容宽高和pdf宽高一样，就只需要一页，也防止内容截断问题）
             */

            // 得到canvas画布的单位是px 像素单位
            var contentWidth = canvas.width;
            var contentHeight = canvas.height;

            // 将canvas转为base64图片

            // 设置pdf的尺寸，pdf要使用pt单位 已知 1pt/1px = 0.75   pt = (px/scale)* 0.75
            // 2为上面的scale 缩放了2倍
            var pdfX = ((contentWidth + 10) / 2) * 0.75;
            var pdfY = ((contentHeight + 500) / 2) * 0.75; // 500为底部留白

            // 设置内容图片的尺寸，img是pt单位
            var imgX = pdfX;
            var imgY = (contentHeight / 2) * 0.75; //内容图片这里不需要留白的距离

            // 初始化jspdf 第一个参数方向：默认''时为纵向，第二个参数设置pdf内容图片使用的长度单位为pt，第三个参数为PDF的大小，单位是pt
            var PDF = new jsPDF("", "pt", [pdfX, pdfY]);

            // 将内容图片添加到pdf中，因为内容宽高和pdf宽高一样，就只需要一页，位置就是 0,0
            PDF.addImage(pageData, "jpeg", 0, 0, imgX, imgY);
            PDF.save(`${name || "down"}.pdf`);
          })
          .catch((err) => {
            console.error(err);
            this.$loadingHide();
            document.body.removeChild(container);
          });
      };

      img.src = base64;
    });

    return false;
  });
};

// 模板四和模板五需要分页处理
export const publicDownPDF2 = function(name, cb, createEleArr) {
  // this.hiddenShadow();
  this.$nextTick(() => {
    // 创建容器 和 头部区域
    var container = document.createElement("div");
    container.style.position = "absolute";
    container.style.width = "1110px";
    container.style.top = "0";
    container.style.left = "0";
    // container.style.transform = "translateY(100%)";

    container.style.zIndex = 10;
    container.style.background = "#fff";
    // container.style.zIndex = -1;
    var companyHeader = document.querySelector("#companyHeadContainer");
    if (companyHeader) {
      var companyHeader = document
        .querySelector("#companyHeadContainer")
        .cloneNode(true);
      if (companyHeader.querySelector(".hot-wrap"))
        companyHeader.querySelector(".hot-wrap").style.visibility = "hidden";
      // companyHeader.querySelector(
      //   "#industry"
      // ).innerHTML = `<h3>审计年份：${this.selectedYear}</h3>`;

      container.appendChild(companyHeader);
      var title = document.createElement("h2");
      title.innerText = name;
      title.style.paddingLeft = "30px";
      container.appendChild(title);
    }

    // 需要生成的元素的id
    createEleArr.map((item, index) => {
      const ele = $(`#${item.id}`);
      const cloneEle = ele.clone(true);
      const canvasCloneEle = cloneEle.find("canvas");

      if (canvasCloneEle[0]) {
        const canvasEle = ele.find("canvas");
        [].map.call(canvasEle, (ele, index) => {
          $(canvasCloneEle[index]).replaceWith(
            `<img style="width: 100%;height: 100%" src="${ele.toDataURL()}">`
          );
        });
      }
      !index &&
        cloneEle.css({
          paddingLeft: "30px",
          paddingTop: "30px",
        });
      container.appendChild(cloneEle[0]);
    });

    // this.$refs.CustomRule.CustomRuleAllTable(container);

    // $("body").html(container);
    // return false;
    var domtoimage = require("dom-to-image");
    $(container)
      .find(".box")
      .css({
        boxShadow: "0 0 8px #fff",
      });
    document.body.append(container);

    domtoimage.toSvg(container).then((base64) => {
      const img = new Image();

      img.onload = () => {
        var canvas = document.createElement("CANVAS");
        canvas.width = img.width;
        canvas.height = img.height;

        const ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0, canvas.width, canvas.height);

        var canvas = setCanvas(canvas);

        var pageData = canvas.toDataURL("image/jpeg", 1.0);

        import("jspdf")
          .then((jsPDF) => {
            jsPDF = jsPDF.default;
            /**jspdf将html转为pdf一页显示不截断，整体思路：
             * 1. 获取DOM
             * 2. 将DOM转换为canvas
             * 3. 获取canvas的宽度、高度（稍微大一点）
             * 4. 将pdf的宽高设置为canvas的宽高
             * 5. 将canvas转为图片
             * 6. 实例化jspdf，将内容图片放在pdf中（因为内容宽高和pdf宽高一样，就只需要一页，也防止内容截断问题）
             */

            // 得到canvas画布的单位是px 像素单位
            var contentWidth = canvas.width;
            var contentHeight = canvas.height;

            // 将canvas转为base64图片
            var pageHeight = (contentWidth / 592.28) * 841.89;
            var leftHeight = contentHeight;
            var position = 0;
            var imgWidth = 595.28;
            var imgHeight = (592.28 / contentWidth) * contentHeight;
            var pdf = new jsPDF("", "pt", "a4");
            if (leftHeight < pageHeight) {
              pdf.addImage(pageData, "JPEG", 0, 0, imgWidth, imgHeight);
            } else {
              while (leftHeight > 0) {
                pdf.addImage(
                  pageData,
                  "JPEG",
                  0,
                  position,
                  imgWidth,
                  imgHeight
                );
                leftHeight -= pageHeight;
                position -= 841.89;
                if (leftHeight > 0) {
                  pdf.addPage();
                }
              }
            }
            pdf.save(`${name || "down"}.pdf`);
          })
          .catch((err) => {
            console.error(err);
            this.$loadingHide();
            document.body.removeChild(container);
          });
      };

      img.src = base64;
    });

    return false;
  });
};

export const excelHandle = {
  maxlength: 0,
  yearCout: 0,
  startYear: 0,
  company_type: 0,
  passArr: {
    现金流量表: [27, 14, 3],
  },
  cerateExcleSequence(num) {
    //数值转字母

    var stringName = "";
    if (num > 0) {
      if (num >= 1 && num <= 26) {
        stringName = String.fromCharCode(64 + parseInt(num));
      } else {
        while (num > 26) {
          var count = parseInt(num / 26);
          var remainder = num % 26;
          if (remainder == 0) {
            remainder = 26;
            count--;
            stringName =
              String.fromCharCode(64 + parseInt(remainder)) + stringName;
          } else {
            stringName =
              String.fromCharCode(64 + parseInt(remainder)) + stringName;
          }
          num = count;
        }
        stringName = String.fromCharCode(64 + parseInt(num)) + stringName;
      }
    }
    return stringName;
  },
  getMaxlength(Sheets, index = 0) {
    for (const key in Sheets) {
      if (Sheets.hasOwnProperty(key)) {
        if (key[0] == "A" && Number(key[1])) {
          index = Number(key.substr(1));
        } else {
          if (key[0] == "B" && Number(key[1])) continue;
          else if (key[0] == "!") continue;
          else delete Sheets[key];
        }
      }
    }
    return index;
  },

  createSheetsYear(Sheets, name) {
    let starthang = 2;
    var startYear = this.startYear;

    for (let index = 0; index <= this.yearCout; index++) {
      starthang++;
      let word = this.cerateExcleSequence(starthang);

      Sheets[word + 2] = {
        t: "s",
        v: `${startYear}年`,
        r: `<t>${startYear}年</t>`,
        h: `${startYear}年`,
        w: `${startYear}年`,
      };
      startYear++;
      for (let k = 0 + 3; k <= this.maxlength; k++) {
        if (
          this.passArr[name] &&
          this.company_type == 0 &&
          this.passArr[name].indexOf(k) + 1
        ) {
          continue;
        }

        Sheets[word + k] = { t: "n", v: 0, w: "0" };
      }
    }
  },
  needWorkbook(workbook, startYear, endYear, company_type) {
    this.yearCout = endYear - startYear;
    this.company_type = company_type;
    this.startYear = Number(startYear);
    for (const key in workbook.Sheets) {
      if (workbook.Sheets.hasOwnProperty(key)) {
        const item = workbook.Sheets[key];
        this.maxlength = this.getMaxlength(item);

        this.createSheetsYear(item, key);
      }
    }
  },
};
import * as sesstion from "@/store/localStorage";
export const get_default_industry_code = (difference) => {
  const Result = sesstion.getLocalData(`industryData`)[
    `${difference == "sw" ? "swResult" : "tjResult"}`
  ];

  let industry_code = "";
  let industry_name = "";
  if (Result.industry_level_five) {
    industry_code = Result["industry_level_five"].industry_code;
    industry_name = Result["industry_level_five"].industry_name;
  } else if (Result.industry_level_four) {
    industry_name = Result["industry_level_four"].industry_name;
    industry_code = Result["industry_level_four"].industry_code;
  } else if (Result.industry_level_three) {
    industry_name = Result["industry_level_three"].industry_name;
    industry_code = Result["industry_level_three"].industry_code;
  } else if (Result.industry_level_two) {
    industry_name = Result["industry_level_two"].industry_name;
    industry_code = Result["industry_level_two"].industry_code;
  } else if (Result.industry_level_one) {
    industry_name = Result["industry_level_one"].industry_name;
    industry_code = Result["industry_level_one"].industry_code;
  }
  var arr = ["industry_level_one", "industry_level_two", "industry_level_three", "industry_level_four", "industry_level_five"]
  arr.map(key =>{
    if(Result[key]){
      return industry_name += Result[key].industry_name +'/'
    } 
  })


  return { industry_code, industry_name };
};
